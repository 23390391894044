import AbstractService from 'services/Service';
import { extractMeta } from 'utils/pagination';

const endpoint = 'users';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async impersonate(id) {
		return await this.api.get(`${endpoint}/${id}/impersonate`);
	}

	async changeStatus(id, status = false) {
		return await this.api.put(`${endpoint}/${id}/activate/${status}`);
	}

	async reset2Fa(id) {
		return await this.api.put(`${endpoint}/${id}/reset-two-factor-auth`);
	}

	async setupQrLogin(id, code) {
		return await this.api.post(`${endpoint}/${id}/qr-code`, {
			userId: id,
			code,
		});
	}

	async setupNfcLogin(id, code) {
		return await this.api.post(`${endpoint}/${id}/nfc`, {
			userId: id,
			code,
		});
	}

	async forgotPassword(username) {
		return this.api.get(`${endpoint}/forgot-password?username=${username}`);
	}

	async getOne(id) {
		const user = await this.api.get(`${endpoint}/${id}`);
		return {
			...user,
			data: this.#mapGetData(user.data),
		};
	}

	async getResponsiblesOptionsList(queryString, locationId, locationLevel) {
		const qs = (queryString || this.queryString)?.concat(
			`&locationId=${locationId}&locationLevel=${locationLevel}`,
		);

		const result = await this.api.get(`${this.endpoint}/options-list?${qs}`);
		return {
			data: result?.data?.map((d) => ({
				value: d?.id,
				label: d?.name + ' ' + d?.lastName + ' (' + d?.userName + ')',
			})),
			meta: extractMeta(result, qs),
		};
	}

	async updateSettings(id, data) {
		return await this.api.put(`${endpoint}/${id}/settings`, this.mapSettingsData(data));
	}

	mapSettingsData = (data) => {
		const { changePasswordNextLogin, loginTwoFaPolicy, passwordExpiresInDays, ...rest } = data;

		return {
			changePasswordNextLogin: changePasswordNextLogin === 'true',
			loginTwoFaPolicy: parseInt(loginTwoFaPolicy),
			passwordExpiresInDays: parseInt(passwordExpiresInDays),
			...rest,
		};
	};

	mapData(data) {
		const { id, gender, profileIds, teams, ...rest } = data;

		Object.keys(rest).forEach((key) => (rest[key] === '' || rest[key] === null) && delete rest[key]);

		//some cases we have key names like settings.loginTwoFaPolicy
		//at this case we have to create a new object settings and put the value inside it
		const settings = {};
		Object.keys(rest).forEach((key) => {
			if (key.includes('.')) {
				const [obj, prop] = key.split('.');
				let value = rest[key];
				if (value === 'true') value = true;
				else if (value === 'false') value = false;
				else if (!isNaN(value)) value = Number(value);
				settings[prop] = value;
				delete rest[key];
			}
		});

		return {
			...rest,

			teams: teams ? JSON.parse(teams) : [],
			profileIds: profileIds,
			gender: gender ? parseInt(gender) : null,
			settings,
		};
	}

	#mapGetData = (data) => {
		return {
			...data,
			dateOfBirth: data?.dateOfBirth ? data.dateOfBirth.split('T')[0] : null,
		};
	};
}

export default Service;
