import { Form, FormEntry } from 'components/form';
import { CheckBox, MultiAddressSelector, ReSelect, SearchSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useForm } from 'hooks';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';

const ProvisionalSettingsForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { getOne } = useCrud(props.service);
	const { getData } = useForm();

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const [taskTypesOptions, setTaskTypesOptions] = useState([]);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);

				fetchTaskTypes(res?.taskGroup?.id);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(myForm.current),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<input type='hidden' name='type' value={props.service.getTypeId()} />

					<FormEntry label='taskGroup'>
						<ReSelect
							isView={isView}
							extraParams='HideAllItem=true'
							name='taskGroupId'
							defaultValue={data?.taskGroup}
							service={taskGroupService}
							onChange={(selected) => {
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>
					<FormEntry label='taskTypes'>
						<SearchSelect
							required
							multiple
							isView={isView}
							name='provisional.taskTypeIdsArray'
							options={taskTypesOptions}
							defaultSelected={data?.provisional?.taskTypeIds}
							excludeSelectedOptionsWhenNotInOptionsList
							isSingleValueOnSelectAll
						/>
					</FormEntry>
					<FormEntry label={'isEditableFromUser'}>
						<CheckBox
							isView={isView}
							name='provisional.isEditableFromUser'
							label='isEditableFromUser'
							selected={data?.provisional?.isEditableFromUser}
						/>
					</FormEntry>
					<FormEntry label='fromLocation'>
						<MultiAddressSelector
							isView={isView}
							name='provisional.from'
							addresses={data?.provisional?.from}
						/>
					</FormEntry>
					<FormEntry label='toLocation'>
						<MultiAddressSelector isView={isView} name='provisional.to' addresses={data?.provisional?.to} />
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default ProvisionalSettingsForm;
