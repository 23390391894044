import { useTranslations } from 'hooks';
import { forwardRef } from 'react';
import { TruncateBeginningString } from 'utils';

const SearchInput = forwardRef(
	(
		{
			disabled,
			isFocused,
			searchTerm,
			isSelectAll,
			placeholder,
			onChange = () => {},
			onFocus = () => {},
			selectedOptions = [],
			truncateLength = 32,
			allItemsAreSelectedMessage = 'allItemsAreSelected',
		},
		ref,
	) => {
		const { translate } = useTranslations();
		return (
			<input
				disabled={disabled}
				ref={ref}
				type='text'
				maxLength={35}
				value={
					isFocused
						? searchTerm
						: isSelectAll
						? translate(allItemsAreSelectedMessage)
						: TruncateBeginningString(selectedOptions?.map((o) => o?.label).join(', '), truncateLength) ||
						  `${translate(placeholder)}...`
				}
				onChange={onChange}
				onFocus={onFocus}
				placeholder={
					isFocused && selectedOptions
						? isSelectAll
							? translate(allItemsAreSelectedMessage)
							: TruncateBeginningString(selectedOptions?.map((o) => o?.label).join(', '), truncateLength)
						: `${translate(placeholder)}...`
				}
				className={`group border-none bg-transparent ring-0 focus:ring-transparent text-gray-900 text-sm w-full p-2.5`}
			/>
		);
	},
);
export default SearchInput;
